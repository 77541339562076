













import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentDropDownFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import { ReviewMethodEnum } from '@/enums/ReviewMethodEnum';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({})
export default class ReviewAssignmentReviewMethodField extends mixins(
  ReviewAssignmentDropDownFieldMixin,
  IntermediateValue
) {
  protected getReviewMethodString(reviewMethod: string): string {
    return ReviewMethodEnum[reviewMethod as keyof typeof ReviewMethodEnum];
  }
}
