





















import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentTextFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({})
export default class ReviewAssignmentNameField extends mixins(ReviewAssignmentTextFieldMixin, IntermediateValue) {}
