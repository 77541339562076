import { render, staticRenderFns } from "./ReviewAssignmentReviewMethodField.vue?vue&type=template&id=43e6faaa&scoped=true&"
import script from "./ReviewAssignmentReviewMethodField.vue?vue&type=script&lang=ts&"
export * from "./ReviewAssignmentReviewMethodField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e6faaa",
  null
  
)

export default component.exports