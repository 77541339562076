import { Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { VModel } from '@/mixins/CommonMixins';

@Component({})
export class ReviewAssignmentFieldMixin extends mixins(VModel) {
  @Prop({
    required: false,
    default: () => false,
  })
  protected editable?: boolean;

  @Prop({
    required: false,
    default: () => null,
  })
  protected fieldState?: boolean | null;

  @Prop({
    required: false,
    default: () => true,
  })
  protected validate?: boolean;
}

@Component({})
export class ReviewAssignmentTextFieldMixin extends mixins(ReviewAssignmentFieldMixin) {
  protected getState(value: string | null, maxLength: number): boolean | null | undefined {
    if (this.validate && value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return this.fieldState;
  }
}

@Component({})
export class ReviewAssignmentDropDownFieldMixin extends mixins(ReviewAssignmentFieldMixin) {
  @Prop({
    required: false,
    default: () => [],
  })
  // Ignore is OK, otherwise this would not work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected options?: any[];
}
