import { render, staticRenderFns } from "./ReviewAssignmentOwnersField.vue?vue&type=template&id=7dd0ae4d&scoped=true&"
import script from "./ReviewAssignmentOwnersField.vue?vue&type=script&lang=ts&"
export * from "./ReviewAssignmentOwnersField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd0ae4d",
  null
  
)

export default component.exports