


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelScopesBar from '@/components/review/ModelScopesBar.vue';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';
import ModelScope from '@/models/ModelScope';
import ModelScopeService from '@/services/ModelScopeService';
import ReviewCanvas from '@/components/Editor/ReviewCanvas.vue';
import { Action, State } from 'vuex-class';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigService from '@/services/ModelConfigService';
import SelectedModelElement from '@/models/SelectedModelElement';
import Edge from '@/models/Edge';
import Node from '@/models/Node';
import AssignmentAttributeBar from '@/components/review/AssignmentAttributeBar.vue';
import TagService from '@/services/TagService';
import Tag from '@/models/users/Tag';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import Point from '@/models/Point';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Project from '@/models/Project';
import ProjectService from '@/services/ProjectService';
import SidebarLeft from '@/components/review/SidebarLeft.vue';
import AttributeBar from '@/components/Editor/AttributeBar.vue';
import CanvasToolbarTop from '@/components/Editor/CanvasToolbar/CanvasToolbarTop.vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component({
  components: { CanvasToolbarTop, AssignmentAttributeBar, ReviewCanvas, ModelScopesBar, SidebarLeft, AttributeBar },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    if (!(this as CreateReviewAssignment).abortingProcess) {
      window.removeEventListener('beforeunload', (this as CreateReviewAssignment).handleBeforeUnload);
      next();
    } else if ((this as CreateReviewAssignment).confirmUnsavedChanges()) {
      next();
    } else {
      next(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (!(this as CreateReviewAssignment).abortingProcess) {
      window.removeEventListener('beforeunload', (this as CreateReviewAssignment).handleBeforeUnload);
      next();
    } else if ((this as CreateReviewAssignment).confirmUnsavedChanges()) {
      window.removeEventListener('beforeunload', (this as CreateReviewAssignment).handleBeforeUnload);
      next();
    } else {
      next(false);
    }
  },
})
export default class CreateReviewAssignment extends mixins(Toasts) {
  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Action('setSelectedElements', { namespace: 'modelEditor' })
  protected setSelectedElements!: (selectedElements: SelectedModelElement[]) => void;

  @Action('clearSelectedElements', { namespace: 'modelEditor' })
  protected clearSelectedElements!: () => void;

  @Action('setCurrentModel', { namespace: 'modelEditor' })
  protected setCurrentModel!: (model: Model | undefined) => void;

  @Action('setCurrentConfig', { namespace: 'modelEditor' })
  protected setCurrentConfig!: (config: ModelConfig | undefined) => void;

  @Action('setCurrentModelBackgroundImage', { namespace: 'modelEditor' })
  protected setCurrentModelBackgroundImage!: (backgroundImage: ModelBackgroundImage | undefined) => void;

  @State('selectedElements', { namespace: 'modelEditor' })
  protected selectedElements!: SelectedModelElement[];

  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;

  @Action('setCurrentProject', { namespace: 'project' })
  protected setCurrentProject!: (project: Project | undefined) => void;

  @State('currentProject', { namespace: 'project' })
  protected currentProject!: Project;

  @Prop({ required: true, default: '' })
  protected readonly projectId!: string;

  @Prop({ required: true, default: '' })
  protected readonly modelId!: string;

  @Action('setAvailableTags', { namespace: 'userManagement' })
  protected setAvailableTags!: (value: Tag[]) => void;

  @State('availableTags', { namespace: 'userManagement' })
  protected availableTags!: Tag[];

  protected showLeftBar = true;
  protected showAssignmentBar = true;

  protected currentAssignment: ReviewAssignment | null = null;

  protected modelScopes: ModelScope[] = [];

  protected selectedScope: ModelScope | null = null;
  protected newScopeDescription = '';
  protected scopeDescriptionState: boolean | null = null;

  protected abortingProcess = true;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_SAVE_ASSIGNMENT)) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);

      this.loadCurrentProject();
    } else {
      this.$router.replace({
        name: 'projectDetails',
        params: { projectId: this.projectId },
      });
    }
  }

  beforeDestroy(): void {
    this.setCurrentModel(undefined);
    this.setCurrentConfig(undefined);
    this.setCurrentModelBackgroundImage(undefined);
    this.setCurrentProject(undefined);
  }

  protected syncLeftSidebarVisibility(visible: boolean): void {
    this.showLeftBar = visible;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-module-boundary-types
  protected handleBeforeUnload(event: any): void {
    event.returnValue = this.confirmUnsavedChangesString;
  }

  protected loadCurrentProject(): void {
    if (hasPermission(PossibleAction.CAN_GET_PROJECT)) {
      this.setLoading(true);
      ProjectService.getById(+this.projectId)
        .then((project) => {
          this.setCurrentProject(project);
          Vue.nextTick(() => {
            this.loadCurrentModel();
          });
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 404) {
            msg = 'Could not find the queried project.';
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ path: '/projects' }).finally(() => {
            this.showToast(title, msg, 'danger');
          });
        });
    } else {
      this.$router.replace({ path: '/projects' }).finally(() => {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      });
    }
  }

  protected loadCurrentModel(): void {
    if (hasPermission(PossibleAction.CAN_GET_MODEL, this.currentProject)) {
      this.setLoading(true);
      ModelService.getById(this.modelId)
        .then((model) => {
          this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(model)));
          Vue.nextTick(() => {
            this.loadConfig();
          });
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Could not load the model.';
          }
          this.$router
            .replace({
              name: 'projectDetails',
              params: { projectId: this.projectId },
            })
            .finally(() => {
              this.showToast(title, msg, 'danger');
            });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadConfig(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      ModelConfigService.getByID(this.currentModel.modelConfigId)
        .then((config) => {
          this.setCurrentConfig(config);
          if (this.currentModel && this.currentModel.hasImage) {
            if (hasPermission(PossibleAction.CAN_GET_IMAGE, this.currentProject)) {
              this.loadImage()
                .then((imageHref: string) => {
                  this.setCurrentModelBackgroundImage(new ModelBackgroundImage(imageHref, new Point(0, 0)));
                })
                .catch(() => {
                  this.showToast(
                    'Failed to read',
                    'Oops, something went wrong while loading the background image',
                    'danger'
                  );
                })
                .finally(() => {
                  this.loadModelScopes();
                });
            } else {
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              this.loadModelScopes();
            }
          } else {
            this.loadModelScopes();
          }
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 404) {
            msg =
              'Could not find the config: ' +
              this.currentModel.modelConfig +
              ' ' +
              this.currentModel.modelConfigVersion;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ name: 'projectDetails', params: { projectId: this.projectId } }).then(() => {
            this.showToast(title, msg, 'danger');
          });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.currentModel && this.currentModel.id) {
        ModelService.getImageByModelId(this.currentModel.id).then((response) => {
          resolve(response);
        });
      } else {
        reject(false);
      }
    });
  }

  protected loadModelScopes(): void {
    if (this.currentModel.id && hasPermission(PossibleAction.CAN_GET_SCOPE, this.currentProject)) {
      ModelScopeService.getAllByModelId(this.currentModel.id)
        .then((modelScopes) => {
          this.showToast('Successfully loaded', modelScopes.length + ' existing model scopes found.', 'info');
          this.modelScopes = modelScopes;
        })
        .catch((backendError) => {
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Could not load the model scope.';
          }
          this.showToast(title, msg, 'danger');
        })
        .finally(() => {
          this.loadTags();
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadTags(): void {
    if (hasPermission(PossibleAction.CAN_GET_TAG)) {
      TagService.getAll()
        .then((tags) => {
          this.setAvailableTags(tags);
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load tags.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected selectScope(args: { scope: ModelScope }): void {
    const selectedElements: SelectedModelElement[] = [];
    this.clearSelectedElements();
    this.selectedScope = args.scope;

    Vue.nextTick(() => {
      for (const node of this.currentModel.nodes) {
        if (this.selectedScope && this.selectedScope.nodeIds.indexOf(node.id) !== -1 && node.target) {
          selectedElements.push(new SelectedModelElement(node, node.target));
        }
      }

      for (const edge of this.currentModel.edges) {
        if (this.selectedScope && this.selectedScope.edgeIds.indexOf(edge.id) !== -1 && edge.target) {
          selectedElements.push(new SelectedModelElement(edge, edge.target));
        }
      }

      for (const drawable of this.currentModel.drawables) {
        if (this.selectedScope && this.selectedScope.drawableIds.indexOf(drawable.id) !== -1 && drawable.target) {
          selectedElements.push(new SelectedModelElement(drawable, drawable.target));
        }
      }

      this.setSelectedElements(selectedElements);
    });
  }

  protected checkModelScope(): void {
    const scope = this.findIdenticalScope();
    if (scope === null) {
      if (this.selectedElements.length > 0) {
        this.$bvModal.show('modal-create');
      } else {
        this.showToast('Model Scope Missing', 'Please select a model scope first.', 'danger');
      }
    } else {
      this.selectedScope = scope;
      this.saveAssignment();
    }
  }

  protected findIdenticalScope(): ModelScope | null {
    let existingScope: ModelScope | null = null;
    for (const scope of this.modelScopes) {
      if (scope.elementCount === this.selectedElements.length) {
        let foundElements = 0;
        this.selectedElements.forEach((selectedElement) => {
          if (
            scope.nodeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.edgeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.drawableIds.indexOf(selectedElement.modelElement.id) > -1
          ) {
            foundElements++;
          }
        });
        if (foundElements === this.selectedElements.length) {
          existingScope = scope;
          break;
        }
      }
    }
    return existingScope;
  }

  protected createModelScope(event: Event): void {
    if (hasPermission(PossibleAction.CAN_SAVE_SCOPE, this.currentProject)) {
      event.preventDefault();
      if (this.currentModel.id && this.newScopeDescription.length > 0 && this.newScopeDescription.length <= 5000) {
        this.setLoading(true);
        const scope = new ModelScope(this.currentModel.id);
        scope.description = this.newScopeDescription;
        this.selectedElements.forEach((value) => {
          if (value.modelElement instanceof Node) {
            scope.nodeIds.push(value.modelElement.id);
          } else if (value.modelElement instanceof Edge) {
            scope.edgeIds.push(value.modelElement.id);
          } else {
            scope.drawableIds.push(value.modelElement.id);
          }
        });
        ModelScopeService.save(scope)
          .then((savedScope) => {
            this.$bvModal.hide('modal-create');
            this.selectedScope = savedScope;
            this.saveAssignment();
          })
          .catch((backendError) => {
            this.setLoading(false);
            if (backendError.response.status === 403) {
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              this.$bvModal.hide('modal-create');
            } else {
              this.showToast('Failed to save', 'An error occurred while saving the model scope.', 'danger');
            }
          });
      } else {
        this.scopeDescriptionState = false;
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected createAssignment(args: { assignment: ReviewAssignment }): void {
    this.currentAssignment = args.assignment;
    this.checkModelScope();
  }

  protected saveAssignment(): void {
    if (
      this.currentAssignment &&
      this.selectedScope &&
      hasPermission(PossibleAction.CAN_SAVE_ASSIGNMENT, this.currentProject)
    ) {
      this.currentAssignment.projectId = this.currentModel.projectId;
      this.currentAssignment.modelScopeId = this.selectedScope.id;
      ReviewAssignmentService.save(this.currentAssignment)
        .then((savedAssignment) => {
          this.abortingProcess = false;
          this.$router.replace({ name: 'projectDetails', params: { projectId: this.projectId } }).then(() => {
            this.showToast(
              'Successfully created',
              "Review Assignment '" + savedAssignment.name + "' successfully created.",
              'success'
            );
          });
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to create', 'Could not create review assignment.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected cancelAssignment(): void {
    this.$router.replace({
      name: 'projectDetails',
      params: { projectId: this.projectId },
    });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected selectAllModelScopes(): void {
    const selectedElements: SelectedModelElement[] = [];
    Vue.nextTick(() => {
      for (const modelScope of this.modelScopes) {
        for (const node of this.currentModel.nodes) {
          if (
            modelScope.nodeIds.indexOf(node.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === node.id) &&
            node.target
          ) {
            selectedElements.push(new SelectedModelElement(node, node.target));
          }
        }

        for (const edge of this.currentModel.edges) {
          if (
            modelScope.edgeIds.indexOf(edge.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === edge.id) &&
            edge.target
          ) {
            selectedElements.push(new SelectedModelElement(edge, edge.target));
          }
        }

        for (const drawable of this.currentModel.drawables) {
          if (
            modelScope.drawableIds.indexOf(drawable.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === drawable.id) &&
            drawable.target
          ) {
            selectedElements.push(new SelectedModelElement(drawable, drawable.target));
          }
        }
      }

      this.setSelectedElements(selectedElements);
    });
  }

  protected confirmUnsavedChanges(): boolean {
    return window.confirm(this.confirmUnsavedChangesString);
  }

  get confirmUnsavedChangesString(): string {
    return 'Do you really want to leave the creation?';
  }
}
