
















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ModelScope from '@/models/ModelScope';
import moment from 'moment';
import SelectedModelElement from '@/models/SelectedModelElement';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Model from '@/models/Model';
import { State } from 'vuex-class';

@Component
export default class ModelScopesBar extends Vue {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction): boolean {
    const permission = hasPermission(action);
    return permission != null && permission;
  }

  @Prop({ required: true, default: [] })
  protected modelScopes!: ModelScope[];

  @Prop({ required: true, default: null })
  protected selectedScope!: ModelScope | null;

  @Prop({ required: false, default: false })
  protected showCreateButton!: boolean;

  @Prop({ required: false, default: false })
  protected showDeleteButton!: boolean;

  @Prop({ required: false, default: false })
  protected showSelectAllButton!: boolean;

  @Prop({ required: true, default: false })
  protected isStandalone!: boolean;

  @State('selectedElements', { namespace: 'modelEditor' })
  protected selectedElements!: SelectedModelElement[];

  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;

  @Watch('selectedElements')
  protected handelSelectionChange(): void {
    if (this.selectedElements.length > 0) {
      const scope = this.findIdenticalScope();
      if (scope != this.selectedScope) {
        this.$emit('update:selected-scope', scope);
      }
      if (scope) {
        Vue.nextTick(() => {
          const scopeElem = document.getElementById('selectedScope');
          if (scopeElem) {
            scopeElem.scrollIntoView(false);
          }
        });
      }
    } else {
      this.$emit('update:selected-scope', null);
    }
  }

  updated(): void {
    for (const scope of this.modelScopes) {
      this.checkCompleteness(scope);
    }
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected selectScope(modelScope: ModelScope): void {
    if (modelScope.isComplete && modelScope === this.selectedScope) {
      this.$emit('update:selected-scope', null);
      this.$emit('select-scope', { scope: new ModelScope('reset_selection') });
    } else if (modelScope.isComplete) {
      this.$emit('update:selected-scope', modelScope);
      this.$emit('select-scope', { scope: modelScope });
    }
  }

  protected findIdenticalScope(): ModelScope | null {
    let existingScope: ModelScope | null = null;
    for (const scope of this.modelScopes) {
      if (scope.elementCount === this.selectedElements.length) {
        let foundElements = 0;
        this.selectedElements.forEach((selectedElement) => {
          if (
            scope.nodeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.edgeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.drawableIds.indexOf(selectedElement.modelElement.id) > -1
          ) {
            foundElements++;
          }
        });
        if (foundElements === this.selectedElements.length) {
          existingScope = scope;
          break;
        }
      }
    }
    return existingScope;
  }

  protected checkCompleteness(scope: ModelScope): void {
    if (scope.isComplete) {
      const mNodeIDs = this.currentModel.nodes.map((node) => node.id);
      for (const sNodeID of scope.nodeIds) {
        if (mNodeIDs.indexOf(sNodeID) === -1) {
          scope.isComplete = false;
          break;
        }
      }
    }
    if (scope.isComplete) {
      const mEdgeIDs = this.currentModel.edges.map((edge) => edge.id);
      for (const sEdgeID of scope.edgeIds) {
        if (mEdgeIDs.indexOf(sEdgeID) === -1) {
          scope.isComplete = false;
          break;
        }
      }
    }
    if (scope.isComplete) {
      const mDrawIDs = this.currentModel.drawables.map((drawable) => drawable.id);
      for (const sDrawID of scope.drawableIds) {
        if (mDrawIDs.indexOf(sDrawID) === -1) {
          scope.isComplete = false;
          break;
        }
      }
    }
  }

  protected createScope(): void {
    this.$emit('create-scope');
  }

  protected deleteScope(scope: ModelScope): void {
    this.$emit('delete-scope', { scope });
  }

  protected selectAllModelScopes(): void {
    this.$emit('select-all-model-scopes');
  }
}
