













import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentDropDownFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import { PriorityEnum } from '@/enums/PriorityEnum';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({})
export default class ReviewAssignmentPriorityField extends mixins(
  ReviewAssignmentDropDownFieldMixin,
  IntermediateValue
) {
  protected getPriorityString(priority: string): string {
    return PriorityEnum[priority as keyof typeof PriorityEnum];
  }
}
