




















import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentDropDownFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({})
export default class ReviewAssignmentOwnersField extends mixins(
  ReviewAssignmentDropDownFieldMixin,
  IntermediateValue
) {}
