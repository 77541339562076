











import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentTextFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import WysiwygEditor from '@/components/WysiwygEditor.vue';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({
  components: { WysiwygEditor },
})
export default class ReviewAssignmentMaterialsField extends mixins(ReviewAssignmentTextFieldMixin, IntermediateValue) {}
