




















import Component, { mixins } from 'vue-class-component';
import { ReviewAssignmentFieldMixin } from '@/components/review/fields/ReviewAssignmentFieldMixin';
import { IntermediateValue } from '@/mixins/CommonMixins';

@Component({})
export default class ReviewAssignmentTargetReviewCountField extends mixins(
  ReviewAssignmentFieldMixin,
  IntermediateValue
) {}
