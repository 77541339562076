





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import Tag from '@/models/users/Tag';
import UserInfo from '@/models/users/UserInfo';
import { State } from 'vuex-class';
import { getCurrentUserID } from '@/auth/AuthService';
import Project from '@/models/Project';
import { PriorityEnum } from '@/enums/PriorityEnum';
import { ReviewMethodEnum } from '@/enums/ReviewMethodEnum';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import ReviewAssignmentTaskDescriptionField from '@/components/review/fields/ReviewAssignmentTaskDescriptionField.vue';
import ReviewAssignmentMethodInstructionsField from '@/components/review/fields/ReviewAssignmentMethodInstructionsField.vue';
import ReviewAssignmentMaterialsField from '@/components/review/fields/ReviewAssignmentMaterialsField.vue';
import ReviewAssignmentPriorityField from '@/components/review/fields/ReviewAssignmentPriorityField.vue';
import ReviewAssignmentReviewMethodField from '@/components/review/fields/ReviewAssignmentReviewMethodField.vue';
import ReviewAssignmentTargetReviewCountField from '@/components/review/fields/ReviewAssignmentTargetReviewCountField.vue';
import ReviewAssignmentNameField from '@/components/review/fields/ReviewAssignmentNameField.vue';
import ReviewAssignmentOwnersField from '@/components/review/fields/ReviewAssignmentOwnersField.vue';
import ReviewAssignmentTagsField from '@/components/review/fields/ReviewAssignmentTagsField.vue';

@Component({
  components: {
    ReviewAssignmentTagsField,
    ReviewAssignmentOwnersField,
    ReviewAssignmentNameField,
    ReviewAssignmentTargetReviewCountField,
    ReviewAssignmentReviewMethodField,
    ReviewAssignmentPriorityField,
    ReviewAssignmentMaterialsField,
    ReviewAssignmentMethodInstructionsField,
    ReviewAssignmentTaskDescriptionField,
  },
})
export default class AssignmentAttributeBar extends Vue {
  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @State('currentProject', { namespace: 'project' })
  protected currentProject!: Project;

  @State('availableTags', { namespace: 'userManagement' })
  protected availableTags!: Tag[];

  @Prop({ required: true, default: true })
  protected showAsDetails!: boolean;

  @Prop({ required: true, default: [] })
  protected tagOptions!: Tag[];

  @Prop({ required: false })
  protected currentAssignment?: ReviewAssignment;

  @Prop({ required: true, default: false })
  protected isStandalone!: boolean;

  @Watch('currentAssignment')
  protected onAssignmentChanged(): void {
    if (this.currentAssignment && this.currentAssignment) {
      this.enteredName = this.currentAssignment.name;
      this.enteredTaskDescription = this.currentAssignment.taskDescription;
      this.enteredMethodInstructions = this.currentAssignment.methodInstructions;
      this.enteredMaterials = this.currentAssignment.materials;
      this.enteredPriority =
        Object.keys(PriorityEnum)
          .filter((value) => PriorityEnum[value] === this.currentAssignment?.priority ?? undefined)
          .pop() ?? null;
      this.enteredTargetReviewCount = this.currentAssignment.targetReviewCount;
      this.enteredReviewMethod =
        Object.keys(ReviewMethodEnum)
          .filter((value) => ReviewMethodEnum[value] === this.currentAssignment?.reviewMethod ?? undefined)
          .pop() ?? null;
      this.enteredTags = [];
      this.currentAssignment.tagIds.forEach((tagId) => {
        const tag = this.availableTags.find((tag) => tag.id === tagId);
        if (tag) {
          this.enteredTags.push(tag);
        }
      });
      this.enteredOwners = this.availableUsers.filter(
        (value) => (this.currentAssignment?.owners.indexOf(value.uid) ?? false) > -1
      );
    }
  }

  protected fieldStates: {
    name: boolean | null;
    taskDescription: boolean | null;
    methodInstructions: boolean | null;
    materials: boolean | null;
    priority: boolean | null;
    targetReviewCount: boolean | null;
    reviewMethod: boolean | null;
  } = {
    name: null,
    taskDescription: null,
    methodInstructions: null,
    materials: null,
    priority: null,
    targetReviewCount: null,
    reviewMethod: null,
  };

  protected enteredName = '';
  protected enteredTaskDescription = '';
  protected enteredMethodInstructions = '';
  protected enteredMaterials = '';
  protected enteredPriority: string | null = null;
  protected enteredTargetReviewCount: number | null = null;
  protected enteredReviewMethod: string | null = null;
  protected enteredTags: Tag[] = [];
  protected enteredOwners: UserInfo[] = [];

  protected priorityOptions: string[] = Object.keys(PriorityEnum);
  protected reviewMethodOptions: string[] = Object.keys(ReviewMethodEnum);

  protected currentUserID: string = getCurrentUserID();

  protected getPossibleOwners(): UserInfo[] {
    return this.availableUsers.filter((user) => {
      const roles = user.roles.map((role) => UserRoleEnum[role]);

      let canOwnAssignment = roles.indexOf(UserRoleEnum.ROLE_ADMIN) !== -1;
      if (
        !canOwnAssignment &&
        this.currentProject &&
        (this.currentProject.owners.indexOf(user.uid) !== -1 || this.currentProject.members.indexOf(user.uid) !== -1)
      ) {
        canOwnAssignment = roles.indexOf(UserRoleEnum.ROLE_CONFIGURATOR) !== -1;
      }
      return canOwnAssignment;
    });
  }

  protected createAssignmentHelper(): void {
    if (!this.showAsDetails && this.validateFields() && this.enteredTargetReviewCount) {
      const reviewAssignment = new ReviewAssignment(
        this.enteredName,
        this.enteredTaskDescription,
        this.enteredMethodInstructions,
        this.enteredMaterials,
        this.enteredPriority as PriorityEnum,
        this.enteredTargetReviewCount,
        this.enteredReviewMethod as ReviewMethodEnum,
        'IN_PROCESS' as AssignmentStateEnum
      );
      reviewAssignment.owners = this.enteredOwners.map((value) => {
        return value.uid;
      });
      if (reviewAssignment.owners.indexOf(this.currentUserID) === -1) {
        reviewAssignment.owners.push(this.currentUserID);
      }
      reviewAssignment.tagIds = [];
      this.enteredTags.forEach((tag) => {
        if (tag.id) {
          reviewAssignment.tagIds.push(tag.id);
        }
      });
      this.$emit('create-assignment', { assignment: reviewAssignment });
    }
  }

  protected cancelAssignment(): void {
    this.$emit('cancel-assignment');
  }

  protected validateFields(): boolean {
    let result = true;
    if (this.enteredName.length <= 0 || this.enteredName.length > 255) {
      this.fieldStates.name = false;
      result = false;
    } else {
      this.fieldStates.name = true;
    }
    if (this.enteredTaskDescription.length <= 0 || this.enteredName.length > 5000) {
      this.fieldStates.taskDescription = false;
      result = false;
    } else {
      this.fieldStates.taskDescription = true;
    }
    if (this.enteredMethodInstructions.length <= 0 || this.enteredName.length > 5000) {
      this.fieldStates.methodInstructions = false;
      result = false;
    } else {
      this.fieldStates.methodInstructions = true;
    }
    if (this.enteredMaterials.length <= 0 || this.enteredName.length > 5000) {
      this.fieldStates.materials = false;
      result = false;
    } else {
      this.fieldStates.materials = true;
    }
    if (!this.enteredPriority) {
      this.fieldStates.priority = false;
      result = false;
    } else {
      this.fieldStates.priority = true;
    }
    if (!this.enteredTargetReviewCount || this.enteredTargetReviewCount <= 0) {
      this.fieldStates.targetReviewCount = false;
      result = false;
    } else {
      this.fieldStates.targetReviewCount = true;
    }
    if (!this.enteredReviewMethod) {
      this.fieldStates.reviewMethod = false;
      result = false;
    } else {
      this.fieldStates.reviewMethod = true;
    }
    return result;
  }
}
